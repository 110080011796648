import { createAction, props } from '@ngrx/store';
import { Faq } from './faq.models';

//user Faqs
export const getFaqs = createAction(
  '[Faq] GetFaqs',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
  }>()
);
export const getFaqSuccess = createAction(
  '[Faq] GetFaqs Success',
  props<{ getFaqResponse: any }>()
);
export const getFaqFailure = createAction(
  '[Faq] GetFaqs Failure',
  props<{ getFaqError: any }>()
);

//Create Faqs
export const createFaqs = createAction(
  '[Faq] CreateFaqs',
  props<{ faq: Faq }>()
);
export const updateFaqs = createAction(
  '[Faq] UpdateFaqs',
  props<{ faq: Faq; id: string }>()
);
export const createFaqSuccess = createAction(
  '[Faq] CreateFaqs Success',
  props<{ createFaqResponse: any }>()
);
export const createFaqFailure = createAction(
  '[Faq] CreateFaqs Failure',
  props<{ createFaqError: any }>()
);

//Create Faqs
export const deleteFaqs = createAction(
  '[Faq] DeleteFaqs',
  props<{ id: number }>()
);
export const delateFaqSuccess = createAction(
  '[Faq] DeleteFaqs Success',
  props<{ deleteFaqResponse: any }>()
);
export const deleteFaqFailure = createAction(
  '[Faq] DeleteFaqs Failure',
  props<{ deleteFaqError: any }>()
);
