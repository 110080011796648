<app-rp-loader></app-rp-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <!--  <h4 class="mb-0 font-size-18">View Faq's</h4> -->
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

                <div class=" d-flex align-items-center">
                    <!-- <div class="col-auto app-search">
                        <div class="position-relative" style="width: 200px;">
                            <input [(ngModel)]="searchText" type="text" class="form-control form-control-sm"
                                placeholder="Search Title, Content" >
                            <span class="bx bx-search-alt"></span>
                        </div>
                    </div> -->
                    <div class="col-auto app-search">
                        <div class="search_icon">
                            <!-- <div class="search_icon">
                                <kendo-textbox [style.height.px]="35" placeholder="Search in all columns...">
                                </kendo-textbox>

                                <button class="btn btn-primary btn-md border-left-none" (click)="performSearch()">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div> -->
                            <div class="search_icon-box d-flex">
                                <kendo-textbox [style.height.px]="35" (keydown.enter)="performSearch()"
                                    [(ngModel)]="searchText" placeholder="Search Title, Content">
                                </kendo-textbox>

                                <button class="btn btn-primary btn-md border-left-none" (click)="performSearch()">
                                    <i class="fa fa-search"></i>
                                </button>
                                <div *ngIf="searchText" class="btn  btn-sm clear-button" (click)="clearSearch()">
                                    <span class="bx bx-x"></span>
                                </div>
                            </div>


                            <!-- <input [(ngModel)]="searchText" type="text" (keydown.enter)="performSearch()"
                                class="form-control search-input" placeholder="Search Title, Content"> -->

                            <!-- Show clear button only when there is text -->
                            <!-- <div *ngIf="searchText" class="btn  btn-sm clear-button" (click)="clearSearch()">
                                <span class="bx bx-x"></span>
                            </div>
                            <div class="btn  btn-sm search-button" (click)="performSearch()">
                                <span class="bx bx-search-alt"></span>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-auto ms-2" *ngIf="role ==roles.SUPERADMIN">
                        <div class="position-relative">
                            <button class="btn btn-primary btn-md" routerLink="/faq/createFaq">Add FAQ's</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">


                <div class="wrapper">
                    <div class="content-container" [id]="contentId">
                        <div class="card-body">
                            @if(faqsList.length<1){ <div class="text-center">
                                <!-- <img src="assets/images/logo-dark.png" alt="" height="20" class="auth-logo-dark mx-auto">
                                            <img src="assets/images/logo-light.png" alt="" height="20" class="auth-logo-light mx-auto"> -->
                                <img src="../../../assets/images/nodata.jpg" alt="" height="250">
                                <h4 class="mt-5">No Data Found</h4>
                        </div>
                        }
                        @else{
                        <!-- <accordion [closeOthers]="true" class="accordion custom-accordian" [isAnimated]="true">
                            @for (faq of faqsList; track $index) {
                            <accordion-group [isOpen]="$index==0?isFirstOpen: false" [heading]="faq.title"
                                class="accordion-item" #group>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                                    @if(faq.files.length>0){ <i
                                        class="mdi mdi-attachment font-size-22 c-mr-1 mdi-attachment-rotate text-primary"
                                        (click)="downloadAttachedFile(faq)"></i>}
                                    <i *ngIf="currentUserString.currentUserRoleDetails.role==roles.SUPERADMIN"
                                        class="mdi mdi-square-edit-outline font-size-18 text-black c-mr-1"
                                        (click)="updateFaq(faq)"></i>
                                    <i *ngIf="currentUserString.currentUserRoleDetails.role==roles.SUPERADMIN" class="
                                        mdi mdi-trash-can-outline font-size-18 text-danger c-mr-1"
                                        (click)="deleteFaq(faq.id)"></i>
                                    <i class="mdi font-size-24"
                                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>
                                <div class="text-muted">
                                    {{faq.content}}
                                </div>
                            </accordion-group>
                            }

                        </accordion> -->

                        <accordion [closeOthers]="true" class="accordion custom-accordian" [isAnimated]="true">
                            <ng-container *ngFor="let faq of faqsList; let i = index; trackBy: trackByIndex">
                                <accordion-group [isOpen]="isOpen[i]" [heading]="faq.title" class="accordion-item"
                                    #group>
                                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                                        <!-- Download attachment icon -->
                                        <i *ngIf="faq.files.length > 0"
                                            class="mdi mdi-attachment font-size-22 c-mr-1 mdi-attachment-rotate text-primary"
                                            (click)="downloadAttachedFile(faq); $event.stopPropagation()"></i>

                                        <!-- Edit icon -->
                                        <i *ngIf="currentUserString.currentUserRoleDetails.role === roles.SUPERADMIN"
                                            class="mdi mdi-square-edit-outline font-size-18 text-primary c-mr-1 "
                                            (click)="updateFaq(faq); $event.stopPropagation()"></i>

                                        <!-- Delete icon -->
                                        <i *ngIf="currentUserString.currentUserRoleDetails.role === roles.SUPERADMIN"
                                            class="mdi mdi-trash-can-outline font-size-18 text-danger c-mr-1"
                                            (click)="deleteFaq(faq.id); $event.stopPropagation()"></i>

                                        <!-- Accordion toggle icon -->
                                        <i class="mdi font-size-24"
                                            [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"
                                            (click)="toggleAccordion(i); $event.stopPropagation()"></i>
                                    </span>
                                    <div class="text-muted">
                                        {{ faq.content }}
                                    </div>
                                </accordion-group>
                            </ng-container>
                        </accordion>

                        }
                    </div>
                    <kendo-datapager [attr.aria-controls]="contentId" [style.width.%]="100" [pageSize]="pageSize"
                        [skip]="skip" [total]="total" [pageSizeValues]="pageSizes" (pageChange)="onPageChange($event)">
                    </kendo-datapager>
                </div>
            </div>

        </div>
    </div>

</div>


</div>