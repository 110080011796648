import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpFaqViewComponent } from './rp-faq-view/rp-faq-view.component';
import { RpCreateFaqComponent } from './rp-create-faq/rp-create-faq.component';

const routes: Routes = [
  { path: 'faq', component: RpFaqViewComponent },
  { path: 'faq/createFaq', component: RpCreateFaqComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FaqRoutingModule {}
