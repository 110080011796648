import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FaqState } from './faq.reducer';

export const getLayoutState = createFeatureSelector<FaqState>('faq');

export const getFaqs = createSelector(
    getLayoutState,
    (state: FaqState) => state.Faq
);


  export const getFaqsList = createSelector(getLayoutState, (state: FaqState) => state.Faq);
  export const getFaqSuccess = createSelector(
    getLayoutState,
    (state: FaqState) => state.getFaqResponse
  );
  export const getFaqFailure = createSelector(
    getLayoutState,
    (state: FaqState) => state.getFaqError
  );

  export const createFaqResponse = createSelector(
    getLayoutState,
    (state: FaqState) => state.createFaqResponse
  );
  export const createFaqError = createSelector(
    getLayoutState,
    (state: FaqState) => state.createFaqError
  );

  export const delateFaqSuccess = createSelector(
    getLayoutState,
    (state: FaqState) => state.deleteFaqResponse
  );
  export const deleteFaqFailure = createSelector(
    getLayoutState,
    (state: FaqState) => state.deleteFaqError
  );
