import { PagerModule } from '@progress/kendo-angular-pager';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { FaqRoutingModule } from './faq-routing.module';
import { RpFaqViewComponent } from './rp-faq-view/rp-faq-view.component';
import { RpCreateFaqComponent } from './rp-create-faq/rp-create-faq.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [RpFaqViewComponent, RpCreateFaqComponent],
  imports: [
    SharedModule,
    CommonModule,
    FaqRoutingModule,
    PagerModule,
    UploadsModule, TextBoxModule,
    ComponentsModule,
    AccordionModule.forRoot(),
  ],
})
export class FaqModule { }
